/* Built In Imports */
import { useContext } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import { getLocale } from '@components/Utility/Shared/SharedService';
import WisdomBlog from '@components/WisdomBlog';
import config from '@config';
import { AuthContext } from '@store/auth-context';

/* Services */
import {
  fetchFooterData, fetchHeaderMenuData, fetchHeaderTopMenuData
} from 'services/commonService';
import {
  fetchAllTopicsList, fetchWisdomLandingPageData,
  getWisdomLandingPaths
} from 'services/wisdomService';

/**
 * Renders the Wisdom Landing Page
 *
 * @function
 * @param {string} region - Current User Region
 * @param {string} language - Current User Language
 * @param {string} headerMenuData - Header Menu Options
 * @param {string} pageData - Wisdom Landing Page Data
 * @param {string} wisdomTopics - Wisdom Topics for Wisdom Filter
 * @param {string} footerData - Page Footer Data
 * @param {string} page - Page Name
 * @returns {ReactElement} Wisdom Landing Page
 */
export default function Wisdom({
  region,
  language,
  headerMenuData,
  pageData,
  wisdomTopics,
  footerData,
  page
}) {
  const authContext = useContext(AuthContext);

  return pageData?.posts?.cards?.length > 0 ? (
    <WisdomBlog
      headerMenuData={headerMenuData}
      pageData={pageData}
      reg={authContext.region}
      lang={authContext.lang}
      footerData={footerData}
      section={page}
      wisdomTopics={wisdomTopics}
      urlpath={`/${authContext.lang}/wisdom`}
    ></WisdomBlog>
  ) : (
    <Box
      color="#28231e"
      fontSize="18px"
      lineHeight="1.7"
      maxW="70%"
      m="0 auto"
      p="45px 0 0"
      minH="30vw"
      sx={{ clear: 'both' }}
    >
      We're sorry. There are no matching results for your selection. Please
      widen your preferences to view results
    </Box>
  );
}

export const getStaticPaths = async () => {
  const wisdomLandingPaths = config.USE_LOCAL_URL
    ? [
      '/en/wisdom',
      '/hi/wisdom',
      '/gu/wisdom',
      '/ta/wisdom',
      '/ar/wisdom',
      '/de/wisdom',
      '/bn/wisdom',
      '/mr/wisdom',
      '/ml/wisdom',
      '/en/wisdom',
      '/es/wisdom',
      '/te/wisdom',
      `/uk/wisdom`,
      `/fa/wisdom`,
    ]
    : await getWisdomLandingPaths('lvl0', '/wisdom');

  return {
    paths: wisdomLandingPaths,
    fallback: false,
  };
};

export const getStaticProps = async ({
  preview,
  previewData,
  params: { region },
}) => {
  const language = region;
  // const mainPageData = await fetchMainPageData(getLocale(region, language));
  const headerTopMenuData = await fetchHeaderTopMenuData(
    getLocale('', language),
    'isotopmenu'
  );
  const headerMenuData = await fetchHeaderMenuData(
    getLocale('', language),
    'iso_non_regional_menu'
  );
  const footerData = await fetchFooterData(
    getLocale('', language),
    'isofooter'
  );
  // console.log('languagess', language);
  const pageData = await fetchWisdomLandingPageData({
    region: '',
    lang: language,
    preview,
    data: previewData,
  });
  const topics = await fetchAllTopicsList('', language);

  return {
    props: {
      headerTopMenuData: headerTopMenuData?.data || null,
      headerMenuData: headerMenuData?.data || null,
      region: '',
      language: language,
      footerData: footerData?.data || null,
      wisdomTopics: topics?.topics || null,
      pageData: pageData || null,
      page: 'Wisdom',
      isPreview: preview ? true : false,
    },
  };
};
